


import FETCHING from '../../library/fetching'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form: {
        id: '',
        nama: '',
        npwp: '',
        alamat: '',
        badan: '',
        bidang: '',
        isi: '',
        file: null,
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db: '',
      listKecamatan: '',
      listDesKel: '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================




      list_data: [],
      list_desa: [],

      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value: "",
      file_old: "",
      cek_load_data: true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus: false,
      mdl_lihat: false,
      mdl_status: false,
      mdl_ditolak: false,
      mdl_diterima: false,
      btn_add: false,


      FETCHING: FETCHING,
      DATA_MASTER: DATA_MASTER,
    }
  },
  methods: {
    getView: function () {
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_PROFIL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value,
          kecamatan_id: this.form.kecamatan_id,
        })
      })
        .then(res => res.json())
        .then(res_data => {
          this.list_data = res_data.data;
          this.page_last = res_data.jml_data;
          this.$store.commit("hideLoading");
          // console.log(res_data);
        });
    },


    addData: function () {
      var formData = new FormData();
      formData.append("nama", this.form.nama);
      formData.append("npwp", this.form.npwp);
      formData.append("alamat", this.form.alamat);
      formData.append("badan", this.form.badan);
      formData.append("bidang", this.form.bidang);
      formData.append("isi", this.form.isi);
      formData.append("file", this.form.file);

      fetch(this.$store.state.url.URL_PROFIL + "addData", {
        method: "POST",
        headers: {
          // "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
        this.getView();
      });
    },


    editData: function () {
      var formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("nama", this.form.nama);
      formData.append("npwp", this.form.npwp);
      formData.append("alamat", this.form.alamat);
      formData.append("badan", this.form.badan);
      formData.append("bidang", this.form.bidang);
      formData.append("isi", this.form.isi);
      formData.append("file", this.form.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_PROFIL + "editData", {
        method: "POST",
        headers: {
          // "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        this.getView();
      });
    },

    removeData: function (idnya, file) {
      fetch(this.$store.state.url.URL_PROFIL + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          id: idnya,
          file: file,
        })
      }).then(res_data => {
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        this.getView();
      });

    },

    selectData: function (data) {
      this.form.id = data.id;
      this.form.nama = data.nama;
      this.form.npwp = data.npwp;
      this.form.alamat = data.alamat;
      this.form.badan = data.badan;
      this.form.bidang = data.bidang;
      this.form.isi = data.isi;
      this.form.pesan = data.pesan;
      this.form.file = data.file;
      this.file_old = data.file;
    },

    usulanBaru: function () {
      fetch(this.$store.state.url.URL_PROFIL + "usulanBaru", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify(this.form)
      }).then(res_data => {
        this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        this.getView();
      });
    },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data) {
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

    // ====================================== CONTOH AUTOCOMPLETE ====================================
    // filter_kecamatan : function (val, update) {
    //     update(() => {
    //       if (val === '') {}
    //       else {FETCHING.getKecamatan(val)}
    //     })
    // },

    // filter_des_kel : function (kecamatan_id, update) {
    //     update(() => {
    //       if (kecamatan_id === '') {}
    //       else {FETCHING.getDesKel(this.form.kecamatan_id)}
    //     })
    // },

    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
    Notify: function (message, positive, icon) {
      this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position: 'top',
        timeout: 500,
      })
    },
    btn_prev: function () {
      this.cek_load_data = true;
      if (this.page_first > 1) {
        this.page_first--
      } else {
        this.page_first = 1;
      }
      this.getView();
    },

    btn_next: function () {
      if (this.page_first >= this.page_last) {
        this.page_first == this.page_last
      } else {
        this.page_first++;
      }
      this.getView();
    },
    indexing: function (index) {
      var idx = ((this.page_first - 1) * this.page_limit) + index
      return idx;
    },
    cari_data: function () {
      this.page_first = 1;
      this.getView();
    },


    // ====================================== PAGINATE ====================================

  },

  mounted() {
    this.getView();
    DATA_MASTER.getBidang();
  },
}
